import React from 'react';
import ReactDOM from 'react-dom';
import {ColorModeScript} from "@chakra-ui/react";
import {BrowserRouter as Router, Route} from "react-router-dom";

import theme from "./theme";
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <Router>
        <ColorModeScript initialColorMode={theme.config.initialColorMode}/>
        <Route exact path="/" component={App}/>
        {/*<Route exact path="/github" component={() => {*/}
        {/*    window.location.href = "https://github.com/Moquent";*/}
        {/*}}/>*/}
        {/*<Route exact path="/discord" component={() => {*/}
        {/*    window.location.href = "https://discord.gg/623552jtcz";*/}
        {/*}}/>*/}
    </Router>,
    document.getElementById('root')
)
;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
