import React from 'react';
import {ChakraProvider, Box, Text, Image, HStack, VStack, Grid} from '@chakra-ui/react';

import moq from "./moq-blue.gif";
import github from "./github.svg";
import discord from "./discord.svg";
import theme from "./theme";
import "./App.css";

function App() {
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    React.useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }

        window.addEventListener('resize', handleResize)
    })

    return (
        <ChakraProvider theme={theme}>
            <Box textAlign="center" fontSize="xl">
                <Grid minH="100vh" p={3} justifyContent={"center"} justifyItems={"center"}>
                    <HStack spacing={30} alignSelf={"center"}>
                        <VStack spacing={2}>
                            <Text style={{fontSize: 80, fontFamily: "Ubuntu", color: "#f1fff8"}}>Hello, I'm
                                Moquent!</Text>

                            <HStack spacing={5}>
                                <a href="https://github.com/Moquent">
                                    <Image src={github} style={{height: 60, width: 60}} class={"imageHover"}/>
                                </a>

                                <a href="https://discord.gg/623552jtcz">
                                    <Image src={discord} style={{height: 65, width: 65}} class={"imageHover"}/>
                                </a>
                            </HStack>
                        </VStack>

                        {dimensions.width > 600
                            ? <Image src={moq} alt="Moq" style={{height: 500, width: 500, borderRadius: 300}}/>
                            : <br/>
                        }
                    </HStack>
                </Grid>

                <Grid>
                    <Text style={{fontSize: 30, fontFamily: "Fira Sans"}}>I'm a 15 year old guy who loves coding and
                        computers 😃
                    </Text>
                </Grid>
            </Box>

            <br/>

            <Text align={"center"} fontFamily={"Maven Pro"}>This website was made with ❤ in React</Text>
        </ChakraProvider>
    );
}

export default App;
